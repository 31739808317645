import 'orejime/dist/orejime.js'
/**
 * Create instance
 */
let buildOrejime = () => {
    const OREJIME = $('#orejime')

    checkCookieConsent()
    attachEventsHandler()

    /**
     * Attach events handler
     */
    function attachEventsHandler () {
        let _noticeParams = OREJIME.find('.orejime-Notice-learnMoreButton')
        let _noticeSave = OREJIME.find('.orejime-Notice-saveButton')
        let _noticeDecline = OREJIME.find('.orejime-Notice-declineButton')

        _noticeSave.on(
            'click',
            () => {
                document.body.classList.remove('orejime-layer-show')
                cookieParamBehaviours()
            }
        )

        _noticeDecline.on(
            'click',
            () => {
                document.body.classList.remove('orejime-layer-show')
                cookieParamBehaviours()
            }
        )

        _noticeParams.on(
            'click',
            () => {
                let _button = $('.orejime-ModalPortal').find('.orejime-Button--save')

                _button.on(
                    'click',
                    () => {
                        document.body.classList.remove('orejime-layer-show')
                    }
                )
            }
        )
    }

    /**
     * Check if orejime is set
     */
    function checkCookieConsent () {
        const IS_OREJIME = document.cookie.replace(/(?:(?:^|.*;\s*)orejime\s*=\s*([^;]*).*$)|^.*$/, '$1')

        // check if orejime cookie exist
        if (IS_OREJIME) {
            document.body.classList.remove('orejime-layer-show')
            cookieParamBehaviours()
        } else {
            document.body.classList.add('orejime-layer-show')
        }
    }

    /**
     * Cookie parameter behaviours
     */
    function cookieParamBehaviours () {
        let _paramContainer = $('<div>').addClass('orejime-paramContainer')
        let _paramAction = $(
            '<button/>',
            {
                text: 'Gestion des cookies',
                class: 'orejime-Button orejime-Button--info orejime-Notice-parameterButton'
            }
        )

        _paramContainer.append(_paramAction)
        OREJIME.append(_paramContainer)

        _paramAction.on(
            'click',
            () => {
                orejime.show() // eslint-disable-line no-undef
            }
        )
    }
}

$(document).ready(() => buildOrejime())
